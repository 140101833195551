import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import BlogList from "../templates/blog-list"

const CategoriesIndex = props => {
  const data = props.data

  const { category } = props.pageContext

  const groups = [...data.allBlogPost.group]
  groups.reverse()

  return (
    <Layout
      location={props.location}
      title={props.data.site.siteMetadata.title}
    >
      <SEO title={category} />
      <h1
        style={{
          marginBottom: 0,
        }}
      >
        Blog - {category}
      </h1>

      <BlogList groups={groups} />
    </Layout>
  )
}

export default CategoriesIndex
export const pageQuery = graphql`
  query BlogPostByCategory($category: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    allBlogPost(filter: { frontmatter: { categories: { eq: $category } } }) {
      group(field: frontmatter___year) {
        fieldValue
        edges {
          node {
            excerpt
            slug
            frontmatter {
              date(formatString: "MMMM DD, YYYY")
              year: date(formatString: "YYYY")
              title
            }
          }
        }
      }
    }
  }
`
